import React, { FunctionComponent } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import VideoBlock from "../VideoBlock";
import Section from "../Section";
import { ImageInterface, SanityCtaBlock, VideoInterface } from "../../types/SanityTypes";
import "./styles.scss";
import CtaBlock from "../CtaBlock";
import { IMAGE_SIZES } from "../../constants";
import logo from "../../images/SMLOGO1.svg";

interface HeroBannerInterface {
  title?: string;
  alignment?: string;
  isVideoBanner?: boolean;
  image?: ImageInterface;
  sanityImage?: any;
  video?: VideoInterface;
  subHeading?: string;
  textHighlight?: string;
  ctaLink?: SanityCtaBlock;
  isSmallBanner?: boolean;
  landingPageVariation?: boolean;
}

const HeroBanner: FunctionComponent<HeroBannerInterface> = ({
  title,
  alignment = "left",
  isVideoBanner,
  sanityImage,
  video,
  ctaLink,
  subHeading,
  textHighlight,
  landingPageVariation,
  isSmallBanner = false
}) => {
  const addImageFallback = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src =
      "https://dev.sheamoisture.com/on/demandware.static/Sites-shm-us-Site/-/default/dwed499d15/images/noimage.jpg";
  };
  console.log(textHighlight, "textHighlight");

  return (
    <Section
      sectionClassName={`hero ${isSmallBanner ? "hero--small" : ""} ${alignment == "split" ? "hero-split" : ""} ${
        landingPageVariation ? "hero--landing" : ""
      }`}
      width={alignment == "split" ? "full-width" : ""}
    >
      {landingPageVariation && <img className="lp-logo" alt="sheamoisture logo" src={logo} />}
      <div className={`hero__wrapper place-${alignment}`}>
        <div className="hero__content">
          {textHighlight && !landingPageVariation && <span className="hero__highlight">{textHighlight}</span>}
          {/* {landingPageVariation && textHighlight && <span className="hero__span">{textHighlight}</span>} */}
          {title && <h1 className="hero__headline">{title}</h1>}
          {landingPageVariation && subHeading && (
            <h1 className="hero__subheadline">
              <span className="hero__span">{textHighlight}</span>
              {subHeading}
            </h1>
          )}
          {landingPageVariation && ctaLink && (
            <CtaBlock
              ctaBlock={ctaLink}
              ctaLabel={ctaLink.ctaLabel}
              linkClassName="c-link__button simple-banner__cta"
            />
          )}
          {(subHeading && !landingPageVariation) || (ctaLink && ctaLink.ctaLabel) ? (
            <div className="flow">
              {subHeading && <p>{subHeading}</p>}
              {ctaLink && ctaLink.ctaLabel && (
                <CtaBlock
                  ctaBlock={ctaLink}
                  ctaLabel={ctaLink.ctaLabel}
                  linkClassName="c-link__button simple-banner__cta"
                />
              )}
            </div>
          ) : null}
        </div>
        {sanityImage && !isVideoBanner && !landingPageVariation && (
          <div className="hero__image-wrapper">
            <SanityImage
              {...sanityImage}
              sizes={IMAGE_SIZES.FULL_WIDTH}
              width={alignment == "split" ? 393 : 1660}
              height={alignment == "split" ? 600 : 400}
              htmlWidth={1660}
              htmlHeight={400}
              loading="eager"
              onError={addImageFallback}
            ></SanityImage>
          </div>
        )}
        {sanityImage && !isVideoBanner && landingPageVariation && (
          <div className="hero__image-wrapper">
            <SanityImage
              {...sanityImage}
              sizes={IMAGE_SIZES.FULL_WIDTH}
              // width={alignment == "split" ? 393 : 1660}
              // height={alignment == "split" ? 600 : 400}
              htmlWidth={1660}
              htmlHeight={400}
              loading="eager"
              onError={addImageFallback}
            ></SanityImage>
          </div>
        )}
        {video && isVideoBanner && (
          <div className="hero__video-wrapper">
            <VideoBlock
              classnames="hero-banner__bg-video"
              webm={video.webm}
              fallback={video.fallback}
              alt={video.alt}
              autoPlay
              muted
              loop
            />
          </div>
        )}
      </div>
    </Section>
  );
};

export default HeroBanner;
